import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          back: "Iar",
        },
        hero: {
          title: "Onde quer que esteja",
          subtitle:
            " Para comunicar com o centro de emergência, pode encontrar os contatos no seu voucher.",
          cta: "Solicitar assistência",
        },
        body: {
          title: "Como usar o ASSIST 365?",
          subtitle: "3 maneiras de receber atendimento médico",
        },
        features: {
          feature1: {
            title: "Coordenação com fornecedores",
            subtitle:
              "Você não precisa adiantar dinheiro, pois efetuamos o pagamento diretamente.",
          },
          feature2: {
            title: "Dr. On-line",
            subtitle:
              "Se os sintomas forem de baixa complexidade, você pode utilizar o serviço de telemedicina.",
          },
          feature3: {
            title: "Para reembolso",
            subtitle:
              "Mal pode esperar pela coordenação, vá ao serviço médico mais próximo para tratá-lo.",
          },
        },
      },
    },

    "es-AR": {
      translations: {
        index: {
          back: "Volver",
        },
        hero: {
          title: "Estés donde estés",
          subtitle:
            "Para comunicarte con la central de emergencias, podrás encontrar los contactos en tu voucher.",
          cta: "Solicitar Asistencia",
        },
        body: {
          title: "¿Cómo usar a ASSIST 365?",
          subtitle: "3 formas de recibir atención médica",
        },
        features: {
          feature1: {
            title: "Coordinación con proveedores",
            subtitle:
              "No necesitás adelantar dinero, ya que nosotros realizamos directamente el pago.",
          },
          feature2: {
            title: "Dr. Online",
            subtitle:
              "Si los síntomas son de baja complejidad, podés utilizar el servicio de telemedicina.",
          },
          feature3: {
            title: "Por reintegro",
            subtitle:
              "No podés esperar a la coordinación, acudí al servicio médico cercano para atenderte.",
          },
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
